<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="公司名称">
          <el-input
              size="small"
              clearable
              v-model="searchForm.compName"
              placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <!--        <el-form-item label="公司代码">-->
        <!--          <el-input-->
        <!--            size="small"-->
        <!--            v-model="searchForm.compCode"-->
        <!--            placeholder="请输入公司代码"-->
        <!--          ></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="所在省">
          <el-select
              size="small"
              clearable
              v-model="searchForm.areaParentId"
              placeholder="请选择所在省">
            <el-option
                v-for="item in areaParentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        noimport="true"
        isdel="true"
        title="公司信息"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableExport="tableExport"
        @tableDel="tableDel"
        @tableAdd="tableAdd">
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="compName" label="公司名称" width="180">
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="compCode"-->
      <!--          label="公司代码"-->
      <!--          align="center"-->
      <!--          width="180">-->
      <!--      </el-table-column>-->
      <el-table-column prop="compAddress" label="公司地址"></el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span class="el-icon-edit btn-icon" @click="editItem(scope.row)"></span>
          <span class="el-icon-delete btn-icon" @click="delItem(scope.row)"></span>
        </template>
      </el-table-column>
    </custom-tables>
    <edit-copany
        :visible.sync="showDialog"
        :title="dialogTitle"
        :item="item"
        @close="showDialog = false"
        @done="editDone"
    ></edit-copany>
  </div>
</template>

<script>
import basic from "../../../api/modules/basic";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import EditCopany from "../../../components/page/basic/EditCopany";
import {downloadFile} from "../../../utils/tool";

export default {
  name: "company",
  components: {EditCopany, SearchHead, CustomTables},
  data() {
    return {
      dialogTitle: "新增公司",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      item: {},
      areaParentList: [],
    };
  },
  mounted() {
    this.getAreaInfo(0);
    this.getInfo();
  },
  methods: {
    getAreaInfo(id) {
      let param = {pid: id};
      basic.getAreaList(param).then((res) => {
        this.areaParentList = res.data;
      });
    },
    getInfo() {
      basic.selectCompanyList(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {
    },
    //导出
    tableExport() {
      let param = {
        currPage: this.searchForm.currPage,
        pageSize: this.searchForm.pageSize,
      };
      basic.getCompanyRecExport(param).then((res) => {
        downloadFile(res)
      }).catch(err => {
        console.log(err, 'err');
      })
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    tableAdd() {
      this.item = {};
      this.dialogTitle = "新增公司";
      this.showDialog = true;
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      console.log(item);
      this.item = item;
      this.dialogTitle = "编辑公司";
      this.showDialog = true;
    },
    delItem(item) {
      this.$confirm(`此操作将永久删除该公司, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        basic.delCompany({compId: item.compId}).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
  },
};
</script>

<style scoped></style>
