<template>
  <custom-dialog :visible.sync="visible" :title="title" @close="colseDialog" @submit="submit">
    <div class="edit-main">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form :model="editForm" label-width="80px" :rules="rules"  ref="editCopanyForm" >
            <el-form-item label="公司名称" prop="compName">
              <el-input size="small" v-model="editForm.compName" placeholder="请输入公司名称"></el-input>
            </el-form-item>
<!--            <el-form-item label="所属机构" prop="orgaId">-->
<!--              <el-input size="small" v-model="editForm.orgaId" placeholder="请输入公司名称"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="公司代码" prop="compCode">-->
<!--              <el-input size="small" v-model="editForm.compCode" placeholder="请输入公司代码"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="省" prop="areaParentId">
              <el-select class="block-select" size="small" v-model="editForm.areaParentId" placeholder="请选择所在省"
                         @change="changeAreaParent">
                <el-option v-for="item in areaParentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市" prop="areaId">
              <el-select class="block-select" size="small" v-model="editForm.areaId" placeholder="请选择所在市"
                         @change="changeArea">
                <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址" prop="compAddress">
              <el-input size="small" v-model="editForm.compAddress" placeholder="请输入公司地址"></el-input>
            </el-form-item>
            <el-form-item label="公司别名" prop="compAlias">
              <el-input size="small" v-model="editForm.compAlias" maxlength="8" placeholder="请输入公司别名，用于左侧菜单"></el-input>
            </el-form-item>
            <el-form-item label="公司logo" prop="compIcon">
              <el-upload
                  class="avatar-uploader"
                  :action="`${uploadUrl}File/upload`"
                  :data="{ type: 1 }"
                  :show-file-list="false"
                  :on-success="uploadSuccess">
                <img
                    v-if="editForm.compIcon"
                    :src="editForm.compIcon"
                    class="avatar"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <baidu-map :center="center"
                     :zoom="zoom" class="mapbox"
                     :scroll-wheel-zoom="true"
                     @ready="handler"
                     @click="getPoint">
            <bm-marker :position="{lng:editForm.compLongitude,lat:editForm.compLatitude}"></bm-marker>
          </baidu-map>
        </el-col>
      </el-row>
    </div>
  </custom-dialog>
</template>

<script>
import basic from "../../../api/modules/basic";
import CustomDialog from "../../common/CustomDialog";
import config from "../../../api/config";
export default {
  name: "EditCopany",
  props:['visible','title','item'],
  components: {CustomDialog},
  watch:{
    item(newVal,oldVal){
      if(newVal.compId){
        this.editForm = newVal
        if(newVal.areaParentId){
          this.getAreaInfo(newVal.areaParentId)
        }
      }else {
        this.editForm = {
          compName:'',
          compCode:'',
          areaParentId:'',
          areaId:'',
          compAddress:'',
          orgaId:0
        }
      }
      this.resetFields()
    }
  },
  data(){
    return{
      center: {lng:116.404,lat:39.915},
      zoom: 15,
      editForm:{
        compName:'',
        compCode:'',
        areaParentId:'',
        areaId:'',
        compAddress:''
      },
      rules: {
        compName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        areaParentId: [
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        compAddress: [
          { required: true, message: '请输入地址', trigger: 'change' }
        ],
      },
      areaParentList:[], // 省
      areaList: [], //市
      uploadUrl: config.uploadUrl,
    }
  },
  mounted() {
    this.getAreaInfo(0)
  },
  methods:{
    getAreaInfo(id){
      let param = {pid:id}
      basic.getAreaList(param).then(res =>{
        console.log(res)
        if(id ==0){
          this.areaParentList = res.data
        }else {
          this.areaList = res.data
        }
      })
    },
    changeAreaParent(id){
      // console.log(id)
      this.editForm.areaId = ''
      this.getAreaInfo(id)
      // 地图的
      this.areaParentList.forEach(item =>{
        if(item.id === id){
          this.center = item.name
        }
      })
    },
    changeArea(id){
      // 市切换
      // 地图的
      this.areaList.forEach(item =>{
        if(item.id === id && item.name !='市辖区'){
          this.center = item.name
        }
      })
    },
    submit(){
      this.$refs.editCopanyForm.validate((valid) => {
        if (valid) {
          if(this.editForm.compId){
            // 编辑
            basic.updateCompany(this.editForm).then(res =>{
              this.$message({
                message: '编辑成功',
                type: 'success'
              });
              this.$emit('done')
            })
          }else {
            // 新增
            basic.newCompany(this.editForm).then(res =>{
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$emit('done')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    colseDialog(){
      this.$emit('close')
    },
    handler({ BMap, map }) {
      let mapStyle = {
        style: 'midnight'
      };
      map.setMapStyle(mapStyle);
    },
    getPoint(e) {
      //点击地图获取一些信息，
      // this.show = true;
      this.editForm.compLongitude = e.point.lng; //通过  e.point.lng获取经度
      this.editForm.compLatitude = e.point.lat; //通过  e.point.lat获取纬度
      let geocoder = new BMap.Geocoder(); //创建地址解析器的实例
      geocoder.getLocation(e.point, (res) => {
        this.editForm.compAddress = res.address;
      });
    },
    resetFields(){
      if(this.$refs.editCopanyForm){
        this.$refs.editCopanyForm.clearValidate()
        this.$refs.editCopanyForm.resetFields()
      }
    },
    uploadSuccess(val) {
      if (val.code == 200) {
        this.editForm.compIcon = val.message;
        this.$forceUpdate();
      }
    },

  }
}
</script>

<style scoped lang="less">
.edit-main{
  padding: 0 20px;
}
.mapbox{
  height: 300px;
  width: 100%;
}

</style>